<template>
  <div
    class="wrap__content"
    v-bind:class="{
      'wrap__content--placeholder': !user || !application,
      'wrap__content--administrator': true,
    }"
  >
    <div class="page__placeholder" v-if="!user || !application">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg
              width="73"
              height="73"
              viewBox="0 0 73 73"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="36.5"
                cy="36"
                r="24"
                stroke="#FFF7E1"
                stroke-width="2"
              />
              <g filter="url(#filter0_d_22_5658)">
                <path
                  d="M16.1604 23C13.5332 26.8866 12 31.5673 12 36.6045C12 50.0778 22.969 61 36.5 61C50.031 61 61 50.0778 61 36.6045C61 34.8685 60.8179 33.1748 60.4716 31.5413"
                  stroke="#FFCC47"
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_22_5658"
                  x="5"
                  y="15.9998"
                  width="63"
                  height="52.0002"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_22_5658"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_22_5658"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user && application">
      <div class="content__page content__page--margintop">
        <div class="container">
          <div class="content__page--back">
            <a :href="'/' + $i18n.locale + '/account/'">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 12H5"
                  stroke="#0E1839"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 19L5 12L12 5"
                  stroke="#0E1839"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {{ $t('system.back') }}
            </a>
          </div>

          <div class="content__title--block">
            <div class="section__title--mini">
              Заявление на субсидирование затрат туроператоров в сфере въездного
              туризма
            </div>
            <div class="content__title section__title">
              <strong>за каждого иностранного туриста №10001</strong>
            </div>
          </div>

          <div class="add_tourist--section add_tourist--section-transition">
            <div class="add_tourist--block">
              <div class="add_tourist__section--title">Данные Заявителя</div>
              <div class="table__block--style table__block--blue">
                <v-data-table
                  :headers="headersApplicant"
                  :items="DataApplicant"
                  :loading="loading"
                  :options.sync="options"
                  hide-default-footer
                >
                  <template v-slot:item.reference="{ item }">
                    <a
                      class="tb__table--views"
                      :href="item.reference_src"
                      data-fancybox="reference"
                    >
                      Посмотреть
                    </a>
                  </template>
                  <template v-slot:item.requisites="{ item }">
                    <a
                      class="tb__table--views"
                      :href="item.requisites_src"
                      data-fancybox="requisites"
                    >
                      Посмотреть
                    </a>
                  </template>
                </v-data-table>
              </div>
            </div>
            <div class="add_tourist--block">
              <div class="add_tourist__section--title">
                Данные иностранного туриста
              </div>
              <div class="table__block--style table__block--blue">
                <v-data-table
                  :headers="headers"
                  :items="userDataSave"
                  :loading="loading"
                  :options.sync="options"
                  hide-default-footer
                >
                  <template v-slot:item.action="{ item }">
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="client_success()"
                      :data-id="item.id"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                          stroke="#33AD28"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="client_close()"
                      :data-id="item.id"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1692 16.4193L13.1047 13.2944L16.0402 10.1695M16.0402 16.4193L10.1692 10.1695M8.35618 4.82628C9.1634 4.76186 9.92974 4.44443 10.5461 3.91919C11.9844 2.69348 14.0998 2.69348 15.5381 3.91919C16.1545 4.44443 16.9207 4.76186 17.728 4.82628C19.6117 4.9766 21.1077 6.47244 21.258 8.35618C21.3223 9.1634 21.6398 9.92974 22.165 10.5461C23.3907 11.9844 23.3907 14.0998 22.165 15.5381C21.6398 16.1544 21.3223 16.9208 21.258 17.7281C21.1077 19.6118 19.6117 21.1076 17.728 21.258C16.9209 21.3223 16.1545 21.6398 15.5381 22.1651C14.0998 23.3907 11.9844 23.3907 10.5461 22.1651C9.92974 21.6398 9.1634 21.3223 8.35618 21.258C6.47245 21.1076 4.9766 19.6118 4.82628 17.7281C4.76186 16.9208 4.44443 16.1544 3.91919 15.5381C2.69348 14.0998 2.69348 11.9844 3.91919 10.5461C4.44443 9.92974 4.76186 9.1634 4.82628 8.35618C4.9766 6.47244 6.47245 4.9766 8.35618 4.82628Z"
                          stroke="#F3574D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>

          <div class="add_tourist--section">
            <div class="add_tourist__section--title">
              Подтверждающие документы
            </div>

            <div class="add_tourist--filelist">
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments1.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копия документа, удостоверяющего личность иностранного
                      туриста
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments1.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments1.fileName"
                        data-fancybox="fileDocuments1"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments1.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments1.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1692 16.4193L13.1047 13.2944L16.0402 10.1695M16.0402 16.4193L10.1692 10.1695M8.35618 4.82628C9.1634 4.76186 9.92974 4.44443 10.5461 3.91919C11.9844 2.69348 14.0998 2.69348 15.5381 3.91919C16.1545 4.44443 16.9207 4.76186 17.728 4.82628C19.6117 4.9766 21.1077 6.47244 21.258 8.35618C21.3223 9.1634 21.6398 9.92974 22.165 10.5461C23.3907 11.9844 23.3907 14.0998 22.165 15.5381C21.6398 16.1544 21.3223 16.9208 21.258 17.7281C21.1077 19.6118 19.6117 21.1076 17.728 21.258C16.9209 21.3223 16.1545 21.6398 15.5381 22.1651C14.0998 23.3907 11.9844 23.3907 10.5461 22.1651C9.92974 21.6398 9.1634 21.3223 8.35618 21.258C6.47245 21.1076 4.9766 19.6118 4.82628 17.7281C4.76186 16.9208 4.44443 16.1544 3.91919 15.5381C2.69348 14.0998 2.69348 11.9844 3.91919 10.5461C4.44443 9.92974 4.76186 9.1634 4.82628 8.35618C4.9766 6.47244 6.47245 4.9766 8.35618 4.82628Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                              stroke="#6B69DF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                              stroke="#6B69DF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments1.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="client_success(fileDocuments1)"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                          stroke="#33AD28"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="client_close(fileDocuments1)"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1692 16.4193L13.1047 13.2944L16.0402 10.1695M16.0402 16.4193L10.1692 10.1695M8.35618 4.82628C9.1634 4.76186 9.92974 4.44443 10.5461 3.91919C11.9844 2.69348 14.0998 2.69348 15.5381 3.91919C16.1545 4.44443 16.9207 4.76186 17.728 4.82628C19.6117 4.9766 21.1077 6.47244 21.258 8.35618C21.3223 9.1634 21.6398 9.92974 22.165 10.5461C23.3907 11.9844 23.3907 14.0998 22.165 15.5381C21.6398 16.1544 21.3223 16.9208 21.258 17.7281C21.1077 19.6118 19.6117 21.1076 17.728 21.258C16.9209 21.3223 16.1545 21.6398 15.5381 22.1651C14.0998 23.3907 11.9844 23.3907 10.5461 22.1651C9.92974 21.6398 9.1634 21.3223 8.35618 21.258C6.47245 21.1076 4.9766 19.6118 4.82628 17.7281C4.76186 16.9208 4.44443 16.1544 3.91919 15.5381C2.69348 14.0998 2.69348 11.9844 3.91919 10.5461C4.44443 9.92974 4.76186 9.1634 4.82628 8.35618C4.9766 6.47244 6.47245 4.9766 8.35618 4.82628Z"
                          stroke="#F3574D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments1)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments2.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Договор на туристское обслуживание
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments2.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments2.fileName"
                        data-fancybox="fileDocuments2"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments2.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments2.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1692 16.4193L13.1047 13.2944L16.0402 10.1695M16.0402 16.4193L10.1692 10.1695M8.35618 4.82628C9.1634 4.76186 9.92974 4.44443 10.5461 3.91919C11.9844 2.69348 14.0998 2.69348 15.5381 3.91919C16.1545 4.44443 16.9207 4.76186 17.728 4.82628C19.6117 4.9766 21.1077 6.47244 21.258 8.35618C21.3223 9.1634 21.6398 9.92974 22.165 10.5461C23.3907 11.9844 23.3907 14.0998 22.165 15.5381C21.6398 16.1544 21.3223 16.9208 21.258 17.7281C21.1077 19.6118 19.6117 21.1076 17.728 21.258C16.9209 21.3223 16.1545 21.6398 15.5381 22.1651C14.0998 23.3907 11.9844 23.3907 10.5461 22.1651C9.92974 21.6398 9.1634 21.3223 8.35618 21.258C6.47245 21.1076 4.9766 19.6118 4.82628 17.7281C4.76186 16.9208 4.44443 16.1544 3.91919 15.5381C2.69348 14.0998 2.69348 11.9844 3.91919 10.5461C4.44443 9.92974 4.76186 9.1634 4.82628 8.35618C4.9766 6.47244 6.47245 4.9766 8.35618 4.82628Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                              stroke="#6B69DF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                              stroke="#6B69DF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments2.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="client_success(fileDocuments2)"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                          stroke="#33AD28"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="client_close(fileDocuments2)"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1692 16.4193L13.1047 13.2944L16.0402 10.1695M16.0402 16.4193L10.1692 10.1695M8.35618 4.82628C9.1634 4.76186 9.92974 4.44443 10.5461 3.91919C11.9844 2.69348 14.0998 2.69348 15.5381 3.91919C16.1545 4.44443 16.9207 4.76186 17.728 4.82628C19.6117 4.9766 21.1077 6.47244 21.258 8.35618C21.3223 9.1634 21.6398 9.92974 22.165 10.5461C23.3907 11.9844 23.3907 14.0998 22.165 15.5381C21.6398 16.1544 21.3223 16.9208 21.258 17.7281C21.1077 19.6118 19.6117 21.1076 17.728 21.258C16.9209 21.3223 16.1545 21.6398 15.5381 22.1651C14.0998 23.3907 11.9844 23.3907 10.5461 22.1651C9.92974 21.6398 9.1634 21.3223 8.35618 21.258C6.47245 21.1076 4.9766 19.6118 4.82628 17.7281C4.76186 16.9208 4.44443 16.1544 3.91919 15.5381C2.69348 14.0998 2.69348 11.9844 3.91919 10.5461C4.44443 9.92974 4.76186 9.1634 4.82628 8.35618C4.9766 6.47244 6.47245 4.9766 8.35618 4.82628Z"
                          stroke="#F3574D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments2)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments3.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Документ, подтверждающий оплату туристского продукта по
                      Договору
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments3.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments3.fileName"
                        data-fancybox="fileDocuments3"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments3.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments3.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1692 16.4193L13.1047 13.2944L16.0402 10.1695M16.0402 16.4193L10.1692 10.1695M8.35618 4.82628C9.1634 4.76186 9.92974 4.44443 10.5461 3.91919C11.9844 2.69348 14.0998 2.69348 15.5381 3.91919C16.1545 4.44443 16.9207 4.76186 17.728 4.82628C19.6117 4.9766 21.1077 6.47244 21.258 8.35618C21.3223 9.1634 21.6398 9.92974 22.165 10.5461C23.3907 11.9844 23.3907 14.0998 22.165 15.5381C21.6398 16.1544 21.3223 16.9208 21.258 17.7281C21.1077 19.6118 19.6117 21.1076 17.728 21.258C16.9209 21.3223 16.1545 21.6398 15.5381 22.1651C14.0998 23.3907 11.9844 23.3907 10.5461 22.1651C9.92974 21.6398 9.1634 21.3223 8.35618 21.258C6.47245 21.1076 4.9766 19.6118 4.82628 17.7281C4.76186 16.9208 4.44443 16.1544 3.91919 15.5381C2.69348 14.0998 2.69348 11.9844 3.91919 10.5461C4.44443 9.92974 4.76186 9.1634 4.82628 8.35618C4.9766 6.47244 6.47245 4.9766 8.35618 4.82628Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                              stroke="#6B69DF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                              stroke="#6B69DF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments3.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="client_success(fileDocuments3)"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                          stroke="#33AD28"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="client_close(fileDocuments3)"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1692 16.4193L13.1047 13.2944L16.0402 10.1695M16.0402 16.4193L10.1692 10.1695M8.35618 4.82628C9.1634 4.76186 9.92974 4.44443 10.5461 3.91919C11.9844 2.69348 14.0998 2.69348 15.5381 3.91919C16.1545 4.44443 16.9207 4.76186 17.728 4.82628C19.6117 4.9766 21.1077 6.47244 21.258 8.35618C21.3223 9.1634 21.6398 9.92974 22.165 10.5461C23.3907 11.9844 23.3907 14.0998 22.165 15.5381C21.6398 16.1544 21.3223 16.9208 21.258 17.7281C21.1077 19.6118 19.6117 21.1076 17.728 21.258C16.9209 21.3223 16.1545 21.6398 15.5381 22.1651C14.0998 23.3907 11.9844 23.3907 10.5461 22.1651C9.92974 21.6398 9.1634 21.3223 8.35618 21.258C6.47245 21.1076 4.9766 19.6118 4.82628 17.7281C4.76186 16.9208 4.44443 16.1544 3.91919 15.5381C2.69348 14.0998 2.69348 11.9844 3.91919 10.5461C4.44443 9.92974 4.76186 9.1634 4.82628 8.35618C4.9766 6.47244 6.47245 4.9766 8.35618 4.82628Z"
                          stroke="#F3574D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments3)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>

              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments4.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копия нотариально засвидетельственной доверенности (при
                      необходимости)
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments4.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments4.fileName"
                        data-fancybox="fileDocuments4"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments4.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments4.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1692 16.4193L13.1047 13.2944L16.0402 10.1695M16.0402 16.4193L10.1692 10.1695M8.35618 4.82628C9.1634 4.76186 9.92974 4.44443 10.5461 3.91919C11.9844 2.69348 14.0998 2.69348 15.5381 3.91919C16.1545 4.44443 16.9207 4.76186 17.728 4.82628C19.6117 4.9766 21.1077 6.47244 21.258 8.35618C21.3223 9.1634 21.6398 9.92974 22.165 10.5461C23.3907 11.9844 23.3907 14.0998 22.165 15.5381C21.6398 16.1544 21.3223 16.9208 21.258 17.7281C21.1077 19.6118 19.6117 21.1076 17.728 21.258C16.9209 21.3223 16.1545 21.6398 15.5381 22.1651C14.0998 23.3907 11.9844 23.3907 10.5461 22.1651C9.92974 21.6398 9.1634 21.3223 8.35618 21.258C6.47245 21.1076 4.9766 19.6118 4.82628 17.7281C4.76186 16.9208 4.44443 16.1544 3.91919 15.5381C2.69348 14.0998 2.69348 11.9844 3.91919 10.5461C4.44443 9.92974 4.76186 9.1634 4.82628 8.35618C4.9766 6.47244 6.47245 4.9766 8.35618 4.82628Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                              stroke="#6B69DF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                              stroke="#6B69DF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments4.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="client_success(fileDocuments4)"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                          stroke="#33AD28"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="client_close(fileDocuments4)"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1692 16.4193L13.1047 13.2944L16.0402 10.1695M16.0402 16.4193L10.1692 10.1695M8.35618 4.82628C9.1634 4.76186 9.92974 4.44443 10.5461 3.91919C11.9844 2.69348 14.0998 2.69348 15.5381 3.91919C16.1545 4.44443 16.9207 4.76186 17.728 4.82628C19.6117 4.9766 21.1077 6.47244 21.258 8.35618C21.3223 9.1634 21.6398 9.92974 22.165 10.5461C23.3907 11.9844 23.3907 14.0998 22.165 15.5381C21.6398 16.1544 21.3223 16.9208 21.258 17.7281C21.1077 19.6118 19.6117 21.1076 17.728 21.258C16.9209 21.3223 16.1545 21.6398 15.5381 22.1651C14.0998 23.3907 11.9844 23.3907 10.5461 22.1651C9.92974 21.6398 9.1634 21.3223 8.35618 21.258C6.47245 21.1076 4.9766 19.6118 4.82628 17.7281C4.76186 16.9208 4.44443 16.1544 3.91919 15.5381C2.69348 14.0998 2.69348 11.9844 3.91919 10.5461C4.44443 9.92974 4.76186 9.1634 4.82628 8.35618C4.9766 6.47244 6.47245 4.9766 8.35618 4.82628Z"
                          stroke="#F3574D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments4)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments5.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Прикрепить пасажирский купон / посадочный талон
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments5.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments5.fileName"
                        data-fancybox="fileDocuments5"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments5.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments5.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1692 16.4193L13.1047 13.2944L16.0402 10.1695M16.0402 16.4193L10.1692 10.1695M8.35618 4.82628C9.1634 4.76186 9.92974 4.44443 10.5461 3.91919C11.9844 2.69348 14.0998 2.69348 15.5381 3.91919C16.1545 4.44443 16.9207 4.76186 17.728 4.82628C19.6117 4.9766 21.1077 6.47244 21.258 8.35618C21.3223 9.1634 21.6398 9.92974 22.165 10.5461C23.3907 11.9844 23.3907 14.0998 22.165 15.5381C21.6398 16.1544 21.3223 16.9208 21.258 17.7281C21.1077 19.6118 19.6117 21.1076 17.728 21.258C16.9209 21.3223 16.1545 21.6398 15.5381 22.1651C14.0998 23.3907 11.9844 23.3907 10.5461 22.1651C9.92974 21.6398 9.1634 21.3223 8.35618 21.258C6.47245 21.1076 4.9766 19.6118 4.82628 17.7281C4.76186 16.9208 4.44443 16.1544 3.91919 15.5381C2.69348 14.0998 2.69348 11.9844 3.91919 10.5461C4.44443 9.92974 4.76186 9.1634 4.82628 8.35618C4.9766 6.47244 6.47245 4.9766 8.35618 4.82628Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                              stroke="#6B69DF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                              stroke="#6B69DF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--text">Нет документа</div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments5.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="client_success(fileDocuments5)"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                          stroke="#33AD28"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="client_close(fileDocuments5)"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1692 16.4193L13.1047 13.2944L16.0402 10.1695M16.0402 16.4193L10.1692 10.1695M8.35618 4.82628C9.1634 4.76186 9.92974 4.44443 10.5461 3.91919C11.9844 2.69348 14.0998 2.69348 15.5381 3.91919C16.1545 4.44443 16.9207 4.76186 17.728 4.82628C19.6117 4.9766 21.1077 6.47244 21.258 8.35618C21.3223 9.1634 21.6398 9.92974 22.165 10.5461C23.3907 11.9844 23.3907 14.0998 22.165 15.5381C21.6398 16.1544 21.3223 16.9208 21.258 17.7281C21.1077 19.6118 19.6117 21.1076 17.728 21.258C16.9209 21.3223 16.1545 21.6398 15.5381 22.1651C14.0998 23.3907 11.9844 23.3907 10.5461 22.1651C9.92974 21.6398 9.1634 21.3223 8.35618 21.258C6.47245 21.1076 4.9766 19.6118 4.82628 17.7281C4.76186 16.9208 4.44443 16.1544 3.91919 15.5381C2.69348 14.0998 2.69348 11.9844 3.91919 10.5461C4.44443 9.92974 4.76186 9.1634 4.82628 8.35618C4.9766 6.47244 6.47245 4.9766 8.35618 4.82628Z"
                          stroke="#F3574D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments5)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>

              <div class="fileitem__archive">
                <a class="archive--link">
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 14.5632L12.8539 20.8056C12.101 21.5704 11.0797 22 10.0149 22C8.9501 22 7.92889 21.5704 7.17594 20.8056C6.423 20.0409 6 19.0037 6 17.9222C6 16.8406 6.423 15.8034 7.17594 15.0387L13.322 8.79625C13.824 8.28642 14.5048 8 15.2147 8C15.9246 8 16.6054 8.28642 17.1073 8.79625C17.6093 9.30608 17.8913 9.99756 17.8913 10.7186C17.8913 11.4396 17.6093 12.1311 17.1073 12.6409L10.9546 18.8833C10.7036 19.1382 10.3632 19.2814 10.0082 19.2814C9.65329 19.2814 9.31289 19.1382 9.06191 18.8833C8.81093 18.6284 8.66993 18.2827 8.66993 17.9222C8.66993 17.5616 8.81093 17.2159 9.06191 16.961L14.7399 11.2008"
                      stroke="#6B69DF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Скачать все файлы архивом
                </a>

                <div class="fileitem__elicience">
                  <!-- fileitem__elicience--active-->
                  Проверено «eLicience»
                  <!--
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.37511 8.49975L7.79176 9.83309L10.6251 7.16642M5.54971 3.63112C6.05796 3.59295 6.54047 3.40484 6.92854 3.09359C7.83412 2.36724 9.16607 2.36724 10.0717 3.09359C10.4598 3.40484 10.9422 3.59295 11.4505 3.63112C12.6365 3.7202 13.5784 4.60663 13.673 5.72291C13.7136 6.20127 13.9135 6.65539 14.2442 7.02063C15.0159 7.87295 15.0159 9.12655 14.2442 9.97889C13.9135 10.3441 13.7136 10.7982 13.673 11.2766C13.5784 12.3929 12.6365 13.2793 11.4505 13.3684C10.9423 13.4066 10.4598 13.5947 10.0717 13.906C9.16607 14.6323 7.83412 14.6323 6.92854 13.906C6.54047 13.5947 6.05796 13.4066 5.54971 13.3684C4.36366 13.2793 3.42183 12.3929 3.32718 11.2766C3.28662 10.7982 3.08676 10.3441 2.75605 9.97889C1.98431 9.12655 1.98431 7.87295 2.75605 7.02063C3.08676 6.65539 3.28662 6.20127 3.32718 5.72291C3.42183 4.60663 4.36366 3.7202 5.54971 3.63112Z" stroke="#33AD28" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  -->
                </div>
              </div>
              <div class="form__block--line fileitem__comment">
                <div class="form__block--input">
                  <textarea
                    placeholder="Написать коментарий..."
                    :class="{ 'textarea--active': commentMessage }"
                    v-model="commentMessage"
                    class="input-linck textarea-linck input-linck--white"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="form__block--history">
              <div class="form__block--title">История всех действий</div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="history__item history__item--new">
                    <div class="history__item--date">
                      <span>27.01.2021</span>
                      <span class="history__item--title">Комментарий</span>
                    </div>
                    <div class="history__item--text">
                      Документ не правильно оформлен, факт пересечения
                      государственной границы РК туриста
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="history__item">
                    <div class="history__item--date">
                      <span>25.01.2021</span>
                    </div>
                    <div class="history__item--text">
                      Отправка заявки №10001
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__wrapper--footer">
              <div class="section--link">
                <button class="next__stage input__button">
                  Направить на согласование
                </button>
                <button
                  class="next__stage input__button btn--blue"
                  @click="$modal.show('statement__eqonaq')"
                >
                  Сделать сверку с «eQonaq»
                </button>
                <button class="next__stage input__button btn--error">
                  Отказать
                </button>
              </div>
              <!--
              <div class="section--link" v-else-if="fileDocuments3.fileClass == 'add_tourist--error'">
                <button class="next__stage input__button" @click="$modal.show('statement__error')">Отказать</button>
              </div>
              -->
              <!--
              <div class="section--link">
                <button class="next__stage input__button" @click="$modal.show('statement__error')">Отказать</button>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__performer"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__performer')"
        >
          <svg
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.5 10.5L10.5 31.5"
              stroke="#0E1839"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.5 10.5L31.5 31.5"
              stroke="#0E1839"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Назначить исполнителя</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="form__block--line">
                <div class="form__block--input">
                  <div class="form__block--title">Выберите исполнителя</div>
                  <multiselect
                    v-model="optionsCheck[0]"
                    :options="optionsCheck"
                    :clearOnSelect="false"
                    :clear-on-select="false"
                    placeholder="Расчетный счет"
                    :hideSelected="true"
                    style="border-color: red"
                  ></multiselect>
                  <!-- input--error -->

                  <span class="input-required input--error">{{
                    $t('registration.required.error')
                  }}</span>
                  <!--
                  <div class="subsidizing__banner--button section--link">
                    <a class="link--active">Добавить +</a>
                  </div>
                  -->
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button">Назначить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__success"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__success')"
        >
          <svg
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.5 10.5L10.5 31.5"
              stroke="#0E1839"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.5 10.5L31.5 31.5"
              stroke="#0E1839"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Зарегистрировать заявление</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="modal__statement">
                <div class="modal__statement--text">
                  На субсидирование затрат туроператоров в сфере въездного
                  туризма<br />
                  <a href="#" target="_blank" class="modal__statement--link"
                    >за каждого иностранного туриста №10001</a
                  >
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button">
                  Зарегистрировать заявление
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__error"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__error')"
        >
          <svg
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.5 10.5L10.5 31.5"
              stroke="#0E1839"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.5 10.5L31.5 31.5"
              stroke="#0E1839"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Проверьте все документы</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="modal__statement">
                <div class="modal__statement--image">
                  <img src="../../assets/img/statement__image.svg" />
                </div>
                <div class="modal__statement--text">
                  Для отправки комментариев, пожалуйста проверьте все документы
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button">Хорошо</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__eqonaq"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__eqonaq')"
        >
          <svg
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.5 10.5L10.5 31.5"
              stroke="#0E1839"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.5 10.5L31.5 31.5"
              stroke="#0E1839"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Запрос в ИС «eQonaq»</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="modal__statement">
                <div class="form__block--placeholder">
                  <div class="form__placeholder--circle">
                    <div class="placeholder__circle">
                      <svg
                        width="73"
                        height="73"
                        viewBox="0 0 73 73"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="36.5"
                          cy="36"
                          r="24"
                          stroke="#FFF7E1"
                          stroke-width="2"
                        />
                        <g filter="url(#filter0_d_22_5658)">
                          <path
                            d="M16.1604 23C13.5332 26.8866 12 31.5673 12 36.6045C12 50.0778 22.969 61 36.5 61C50.031 61 61 50.0778 61 36.6045C61 34.8685 60.8179 33.1748 60.4716 31.5413"
                            stroke="#FFCC47"
                            stroke-width="4"
                            stroke-linecap="round"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_22_5658"
                            x="5"
                            y="15.9998"
                            width="63"
                            height="52.0002"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2.5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_22_5658"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_22_5658"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <div class="form__placeholder--text">
                    Идет проверка лицензии, подождите...
                  </div>
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button">Сохранить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { api } from '../../boot/axios'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ViewsTourist',
  components: {
    Multiselect,
  },
  data() {
    return {
      itemsGallery: [
        '/favicon.png',
        '/favicon.png',
        '/favicon.png',
        '/favicon.png',
      ],
      index: null,
      commentMessage: null,

      user: null,
      application: null,

      optionsCheck: ['Исполнитель 1', 'Исполнитель 2', 'Исполнитель 3'],
      headers: [
        { text: 'ФИО туриста', value: 'name', sortable: false },
        { text: '№ документа ', value: 'passport', sortable: false },
        { text: 'Уведомление ', value: 'notification', sortable: false },
        { text: 'Гражданство', value: 'region', sortable: false },
        { text: 'Действие', value: 'action', align: 'center', sortable: false },
      ],
      userDataSave: [
        {
          name: 'Рамазан Есенов',
          passport: '6345453223',
          notification: '№43124 19 ноября 2021',
          region: 'США',
          period: '18 ноября 2021 - 20 ноября 2021',
        },
      ],

      loading: false,
      options: {
        itemsPerPage: 1,
      },

      headersApplicant: [
        { text: 'Заявитель', value: 'name', sortable: false },
        { text: 'БИН/ИИН', value: 'iin', sortable: false },
        { text: 'Лицензия', value: 'license', sortable: false },
        {
          text: 'Справка о задолженности',
          value: 'reference',
          sortable: false,
        },
        { text: 'Реквизиты', value: 'requisites', sortable: false },
      ],
      DataApplicant: [
        {
          name: 'ИП  «Арсымбетов»',
          iin: '870613300057',
          license: '№45Т412543235 до 01.12.2022 г.',
          reference_src: '#',
          requisites_src: '#',
        },
      ],

      fileDocuments1: {
        fileName: 'Название документа.doc',
        fileClass: '',
      },
      fileDocuments2: {
        fileName: 'Название документа.doc',
        fileClass: 'add_tourist--success',
      },
      fileDocuments3: {
        fileName: 'Название документа.doc',
        fileClass: 'add_tourist--error',
      },
      fileDocuments4: {
        fileName: 'Название документа.doc',
        fileClass: '',
      },
      fileDocuments5: {
        fileName: '',
        fileClass: '',
      },
    }
  },
  methods: {
    client_null(fileDocuments) {
      console.log('client_null')
      fileDocuments.fileClass = ''
    },
    client_success(fileDocuments) {
      console.log('client_success')
      fileDocuments.fileClass = 'add_tourist--success'
    },
    client_close(fileDocuments) {
      console.log('client_close')
      fileDocuments.fileClass = 'add_tourist--error'
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/ru/login')
    }

    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
      }
    })

    api.get('applications/' + this.$route.params.id).then((response) => {
      if (response.data) {
        this.application = response.data
      }
    })
  },
}
</script>

<style scoped></style>
